<template>
  <v-container class="follower-bar-container">
    <v-btn
      v-if="!isClient"
      outlined
      :color="isFollower ? 'green lighten--1' : 'orange'"
      @click="toggleFollow"
    >
      {{ isFollower ? $t('followerBar.following') : $t('followerBar.follow') }}
    </v-btn>
    <v-list
      dense
      min-width="300px"
      max-width="300px"
      elevation="1"
      v-if="brokerList.length > 0"
    >
      <v-list-group sub-group no-action>
        <template v-slot:activator>
          <v-list-item-title>{{
            $t('followerBar.contacts')
          }}</v-list-item-title>
        </template>
        <v-list-item
          v-for="broker in brokerList"
          :key="broker.email"
          dense
          three-line
          class=""
        >
          <v-list-item-content>
            <v-list-item-title>{{ broker.name }}</v-list-item-title>
            <v-list-item-subtitle v-if="broker.email">
              <a :href="`mailto:${broker.email}`"
                >{{ $t('followerBar.email') }}: {{ broker.email }}</a
              >
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="broker.phone">
              <a :href="`tel:${broker.phone}`"
                >{{ $t('followerBar.phone') }}: {{ broker.phone }}</a
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  methods: {
    toggleFollow() {
      if (this.isFollower) {
        this.$store.dispatch('mtpl/unfollowClaim', this.$route.params.id)
      } else {
        this.$store.dispatch('mtpl/followClaim', this.$route.params.id)
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      followers: 'mtpl/followers',
    }),
    isFollower() {
      let id = this.user.id
      let index = this.followers.findIndex((follower) => follower.id == id)
      return index > -1
    },
    isClient() {
      return this.$store.getters['user/role'] == 'client'
    },
    brokerList() {
      return this.followers
        .filter(
          (follower) => follower.role == 'account' || follower.role == 'admin'
        )
        .map((el) => ({
          name: `${el.firstName} ${el.lastName}`,
          email: el.email,
          phone: el.phone,
        }))
    },
  },
}
</script>

<style lang="scss" scoped>
.follower-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
</style>
